<input type="file" [max]="maxFiles" hidden [accept]="acceptedFiles" (change)="onChange($event)" #fileInput />

<div class="content">
  <button *ngIf="(this.images.length < 1 || maxFiles > 1) && !isLoadingFile" type="button" class="button-uploader"
    matTooltip="Subir archivo" (click)="fileInput.click()">
    <img src="../../../../../../../assets/svg/cloud-upload.svg" alt="">
    <p>Click to upload a file</p>
  </button>
  <div class="spinner">
    <ui-spinner *ngIf="isLoadingFile" [diameter]="'50'"></ui-spinner>
  </div>
  <div class="img" *ngIf="images.length > 0">
    <p class="img-title" *ngIf="maxFiles > 1">{{ showImagesTitle(images.length) }} </p>
    <div class="loop-container">
      <div *ngFor="let image of images; let i = index" class="images-container">
        <div class="item">
          <div class="item-title">
            <p>{{
              image.name
              ? (image.name.substring(0, 22) + (image.name.length > 22 ? '...' : '')) : 'No tiene' }}</p>
            <p class="size">
              {{ sizeInMb(image.size) }}
            </p>
          </div>
          <button mat-icon-button color="primary" (click)="removeImage(i)" [disabled]="readOnly">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
