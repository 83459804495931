import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot,Router, CanActivate } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class KeycloackAuthGuard extends KeycloakAuthGuard implements CanActivate {

  constructor(
      router: Router,
      protected readonly keycloak: KeycloakService
  ) {
      super(router, keycloak);
  }

  public async isAccessAllowed(
      route: ActivatedRouteSnapshot,
  ) {
      
  
      if (!this.authenticated ) {
          await this.keycloak.login();
      }

      await this.keycloak.updateToken(0)

      const requiredRoles = route.data['roles'];

      if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
          return true;
      }

      console.log('requiredRoles', requiredRoles);
      console.log(this.roles);
      
      // Allow the user to proceed if all the required roles are present.        
      return requiredRoles.every((role) => this.roles.includes(role));
  }

}
