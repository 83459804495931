import { environment } from '../../environments/environment';

export class ConfiguracionUrl {

  getUrlMSUsuarios() {
    return environment.urlApiMsExperience + "user-back/api/user"
  }

  getUrlMSActualizarUsuario() {
    return environment.urlApiMsExperience + "user-back/api/user"
  }

  getUrlMSCrearUsuario() {
    return environment.urlApiMsExperience + "user-back/api/user"
  }

  getUrlMSObtenerRoles() {
    return environment.urlApiMsExperience + "user-back/api/userRoles"
  }

  getUrlMSOperaciones() {
    return environment.urlApiMsExperience + "operation-back/api/operations"
  }

  getUrlMSPushPay() {
    return environment.urlApiMsExperience + "operation-back/api/pushpay"
  }

  getUrlMSDashboardOperaciones() {
    return environment.urlApiMsExperience + "operation-back/api/dashboard"
  }


  getUrlMsFileStorage() {
    return environment.urlApiMsExperience + "operation-back/api/file-storage/liquidation"
  }


  getUrlMsUploadLiquidationFile() {
    return environment.urlApiMsExperience + "operation-back/api/file-storage/upload"
  }


  getUrlMSCheckSession() {
    return environment.urlApiMsUserBack + "userSession"
  }

  getUrlMsReporteOperaciones() {
    return environment.urlApiMsExperience + "operation-return/api/" + "report"
  }
  getUrlComercios() {
    return environment.urlApiMsExperience + "merchant/api"
  }

  getUrlMerchantRate() {
    return environment.urlApiMsExperience + "commission-back/api"
  }

  getUrlProcessorRouting() {
    return environment.urlApiMsExperience + "routing/api"
  }

  getUrlMasterParameters() {
    return environment.urlApiMsExperience + "master-parameters/api"
  }

  getUrlBalance() {
    return environment.urlApiMsExperience + "operation-return/api/report"
  }

  getUrlExperience() {
    return environment.urlApiMsExperience;
  }


}
