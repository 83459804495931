<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row>
        <div class="title">
            <span class="dialog-title">
                Sesión Inactiva
            </span>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content>
    <div class="content">
        <div class="content-image">
            <img src="../../../../../../../assets/svg/warning-circle-svgrepo-com.svg" alt=""> 
        </div>
        <span class="message">Notamos que no estás realizando ninguna acción. ¿Deseas conservar la sesión?  </span>
        <span class="timer">Tu sesión finalizará en {{timer + 1}} {{ timer + 1 == 1 ? 'segundo' : 'segundos'}}</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
    <div>
        <ui-button [full_width]="true" icon="cancel" [bg_white]="true"
        (click)="logout()"  >{{ 'ELEM_GENERIC.CANCELAR' | translate }}</ui-button>
    </div>

    <div>
        <ui-button [full_width]="true" icon="check" [bg_white]="true" (click)="confirmSession()"
           >{{ 'ELEM_GENERIC.CONFIRMAR' | translate }}
        </ui-button>
    </div>
 
</mat-dialog-actions>