<div class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/MonnetNav.png">
        <!-- <span class="logo-text">PAYOUT</span> -->
    </div>

    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>

</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
    [ngClass]="fuseConfig.layout.navbar.primaryBackground">
    <fuse-navigation layout="vertical"></fuse-navigation>

   
</div>


<div class="quest" *ngIf="showQuestionnaire() && isNavbarOpened()">
    <div class="card">
        <h3> {{ 'QUESTIONNAIRE.TITLE' | translate  }}</h3>
        <p class="subtitle">{{ 'QUESTIONNAIRE.SUBTITLE_1' | translate  }} <br><p class="color">{{ 'QUESTIONNAIRE.SUBTITLE_2' | translate  }}</p></p>
        <span>{{ 'QUESTIONNAIRE.DATE' | translate  }}</span>
        <a (click)="redirectTo()">{{ 'QUESTIONNAIRE.ACTION' | translate  }}</a>
    </div>
</div>

<div class="navbar-footer" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
   
    <div class="widget">
        <app-support-widget [sidebarStyle]="true" [metadata]="metadata"></app-support-widget>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxLayoutAlign="center start">
            <img style="width: 25px;" src="assets/icons/others/planet.png">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <div>
                <button id="esBtn" class="langBtn" [ngClass]="language=='es' ? 'langBtnSelected' : 'langBtn'"
                    (click)="setLanguage('es')">ES</button>
            </div>
            <div>
                <h1>/</h1>
            </div>

            <div>
                <button id='enBtn' class="langBtn" [ngClass]="language=='en' ? 'langBtnSelected' : 'langBtn'"
                    (click)="setLanguage('en')">EN</button>
            </div>
        </div>
    </div>


</div>