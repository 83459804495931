<button
  mat-icon-button
  [ngClass]="getClass()"
  [disabled]="disabled || isLoading"
  [type]="type"
  [color]="color"
  (click)="onEmit()"
>
  <mat-spinner
    *ngIf="isLoading"
    [diameter]="20"
    [color]="spinnerColor"
  ></mat-spinner>
  <mat-icon *ngIf="icon && !isLoading">{{ icon }}</mat-icon>
  <img *ngIf="svg_path && !isLoading" [src]="svg_path" />
  <span *ngIf="!isLoading"><ng-content></ng-content></span>
</button>
