import { environment } from "environments/environment";
import { KeycloakService } from "keycloak-angular";

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
    return () =>
      keycloak.init({
        config: {
          url: environment.keycloak.keycloakUrl,
          realm: environment.keycloak.keycloakRealm,
          clientId: environment.keycloak.keycloakClientId,
          
        },
        initOptions: {
         checkLoginIframe: false,
         redirectUri: environment.keycloak.redirectUri,

        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets', environment.freshDeskUrlApi] 
               
      });
  }
