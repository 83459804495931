import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CreateHelpDeskTicketPayload } from '../../interfaces/support.interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FileUploaderImages } from '../../interfaces/file-uploader.interfaces';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';

@Component({
    selector: 'app-support-form',
    templateUrl: './support-form.component.html',
    styleUrls: ['./support-form.component.scss']
})
export class SupportFormComponent implements OnInit, OnDestroy {
    @ViewChild("fileUploaderComponent", { static: true })
    fileUploaderComponent: FileUploaderComponent;

    private subscriptions: Subscription[] = [];
    private fileUpladerImages: FileUploaderImages[] = [];

    private imageMaxFileError: boolean = false;
    private imageMaxSize: boolean = false;

    @Input() set formInput(value: CreateHelpDeskTicketPayload) {
        this.form.setValue(value.helpdesk_ticket);
    };

    @Output() onFormChange: EventEmitter<CreateHelpDeskTicketPayload> = new EventEmitter<CreateHelpDeskTicketPayload>();

    public description_html: FormControl = new FormControl('', [Validators.required, Validators.maxLength(1000)]);
    public subject: FormControl = new FormControl('', [Validators.required, Validators.maxLength(50)]);
    public email: FormControl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.email]);
    public requester_name: FormControl = new FormControl('', [Validators.required, Validators.maxLength(50)]);
    public custom_field: FormControl = new FormControl('', [Validators.required, Validators.maxLength(50)]);
    public atachments: FormControl = new FormControl([], []);

    form: FormGroup = new FormGroup({
        description_html: this.description_html,
        subject: this.subject,
        email: this.email,
        requester_name: this.requester_name,
        custom_field: this.custom_field,
        atachments: this.atachments,
    });

    get maxFilesError(): boolean {
        return this.imageMaxFileError;
    }

    get maxSizeError(): boolean {
        return this.imageMaxSize;
    }

    constructor() { }

    ngOnInit(): void {
        const formChangeValueSubscription = this.form.valueChanges.subscribe((value: CreateHelpDeskTicketPayload) => {
            this.onFormChange.emit(value);
        })

        this.subscriptions.push(formChangeValueSubscription);
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach((subscription: Subscription) => {
                subscription.unsubscribe();
            })
        }
    }

    onMaxFileValidation(event: boolean): void {
        this.imageMaxFileError = event;
    }

    onImagesChange($event: FileUploaderImages[]): void {
        this.fileUpladerImages = $event;
        this.atachments.setValue(this.fileUpladerImages);
    }

    onMaxSizeValidation(event: boolean): void {
        this.imageMaxSize = event;
    }
}
