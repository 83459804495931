import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseTableComponent } from './components/base-table/base-table.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
// import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { MatOptionModule, NativeDateModule, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PageTemplateComponent } from './components/page-template/page-template.component';
import { ButtonComponent } from './components/button/button.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MovementTypeSelectComponent } from './components/movement-type-select/movement-type-select.component';
import { DescriptionTypeSelectComponent } from './components/description-type-select/description-type-select.component';
import { BalanceConsultationWidgetComponent } from './components/balance-consultation-widget/balance-consultation-widget.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { FileLoaderDialogComponent } from './components/file-loader-dialog/file-loader-dialog.component';
import { InputLabelComponent } from './components/input-label/input-label.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CustomSpinnerComponent } from './components/custom-spinner/custom-spinner.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MatSortModule } from '@angular/material/sort';
import { MenuComponent } from './components/menu/menu.component';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ProcessorCashSelectComponent } from './components/processor-cash-select/processor-cash-select.component';
import { CurrencyFieldComponent } from './components/currency-field/currency-field.component';
import { NgxCurrencyModule } from "ngx-currency";
import { MatToolbarModule } from '@angular/material/toolbar';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';

export const customCurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    thousands: ".",
    nullable: true
};

@NgModule({
    declarations: [
        BaseTableComponent,
        InputFieldComponent,
        InputSelectComponent,
        DatepickerComponent,
        PageTemplateComponent,
        ButtonComponent,
        IconButtonComponent,
        ConfirmationDialogComponent,
        MovementTypeSelectComponent,
        DescriptionTypeSelectComponent,
        BalanceConsultationWidgetComponent,
        FormGroupComponent,
        FileLoaderDialogComponent,
        InputLabelComponent,
        SpinnerComponent,
        MenuComponent,
        CustomSpinnerComponent,
        NavbarComponent,
        SlideToggleComponent,
        ProcessorCashSelectComponent,
        CurrencyFieldComponent,
        FileUploaderComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatOptionModule,
        MatSelectModule,
        MatDatepickerModule,
        NativeDateModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSortModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatToolbarModule,
        MatSlideToggleModule,
        TranslateModule.forChild(),
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ],
    exports: [
        BaseTableComponent,
        InputFieldComponent,
        InputSelectComponent,
        DatepickerComponent,
        PageTemplateComponent,
        ButtonComponent,
        IconButtonComponent,
        MovementTypeSelectComponent,
        DescriptionTypeSelectComponent,
        BalanceConsultationWidgetComponent,
        FileLoaderDialogComponent,
        MenuComponent,
        FormGroupComponent,
        SpinnerComponent,
        CustomSpinnerComponent,
        NavbarComponent,
        SlideToggleComponent,
        ProcessorCashSelectComponent,
        CurrencyFieldComponent,
        FileUploaderComponent,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ]
})
export class UiModule { }
