import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class SharedService {

    //recibimos el dato
  private messageSource = new BehaviorSubject<any>('');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  //toma el valor que se esta enviando
  changeMessage(message: any) {
    this.messageSource.next(message)
  }

  private messageSourceMoneda = new BehaviorSubject<any>('');
  currentMessageMoneda = this.messageSourceMoneda.asObservable();


  //toma el valor que se esta enviando
  changeMessageMoneda(message: any) {
    this.messageSourceMoneda.next(message)
  }

  private messageSourceMerchantId = new BehaviorSubject<any>('');
  currentMessageMerchantId = this.messageSourceMerchantId.asObservable();


  //toma el valor que se esta enviando
  changeMessageMerchantId(message: any) {
    this.messageSourceMerchantId.next(message)
  }

}