import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateHelpDeskTicketPayload, HelpDeskTicketResponse } from '../interfaces/support.interfaces';
import { Observable } from 'rxjs';
import { freshDeskApiSettings } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class FreshDeskApiService {
    private freshDeskUrlApi: string = ''
    private httpHeaders: any

    constructor(
        private httpClient: HttpClient,
    ) {
        this.freshDeskUrlApi = freshDeskApiSettings.freshDeskUrlApi

        this.httpHeaders = {
            'Authorization': `Basic ${btoa(freshDeskApiSettings.freshDeskApiKey + ':X')}`,
        };
    }

    createTicket(ticket: CreateHelpDeskTicketPayload): Observable<HelpDeskTicketResponse> {
        const formData = new FormData();

        formData.append('email', ticket.helpdesk_ticket.email);
        formData.append('subject', ticket.helpdesk_ticket.subject);
        formData.append('description', ticket.helpdesk_ticket.description_html);
        formData.append('name', ticket.helpdesk_ticket.requester_name);
        formData.append('priority', '2');
        formData.append('status', '2');
        formData.append('group_id', '70000192423')
        formData.append('responder_id', '70003768927');
        formData.append('[custom_fields][cf_fsm_contact_name]', ticket.helpdesk_ticket.requester_name);

        if (ticket.helpdesk_ticket.atachments.length > 0) {
            for (let i = 0; i < ticket.helpdesk_ticket.atachments.length; i++) {
                formData.append('attachments[]', ticket.helpdesk_ticket.atachments[i].file);
            }
        }

        return this.httpClient.post<HelpDeskTicketResponse>(this.freshDeskUrlApi, formData, {
            headers: this.httpHeaders,
        })
    }
}
