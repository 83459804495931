import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { IUserDataSubject } from 'app/main/interfaces';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  constructor(public keycloakService: KeycloakService, private http: HttpClient) {
    
  }


  logoutKeycloak(refreshToken?: string) {
    const token = 'Bearer ' + this.keycloakService.getKeycloakInstance().token;
  
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);
  
    const body = new HttpParams()
      .set('refresh_token', refreshToken ? refreshToken : this.keycloakService.getKeycloakInstance().refreshToken)
      .set('client_id', environment.keycloak.keycloakClientId);
  
    return this.http.post(environment.keycloak.keycloakUrl +'/realms/back-office-payin/protocol/openid-connect/logout', body.toString(), { headers });
  }

  validateCredentials( password?: string) {
    // Configura las cabeceras de la solicitud
    const token = 'Bearer ' + this.keycloakService.getKeycloakInstance().token;
  
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const body = new HttpParams()
      .set('token', this.keycloakService.getKeycloakInstance().token)
       .set('client_id', environment.keycloak.keycloakClientId)
       .set('client_secret', environment.keycloak.client_secret)
       .set('username', this.keycloakService.getKeycloakInstance().tokenParsed['preferred_username'])
        .set('password', password)
        .set('grant_type', 'password')
        .set('scope', 'openid');

 
    // Realiza la solicitud HTTP POST
    return this.http.post(environment.keycloak.keycloakUrl + '/realms/back-office-payin/protocol/openid-connect/token', body, { headers });
  }

  
}
