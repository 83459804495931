import { Component, EventEmitter, Output, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { FileUploaderImages } from '../../../support/interfaces/file-uploader.interfaces';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnDestroy, OnInit {
  @ViewChild("fileInput") fileInput: any;

  @Input() control: FormControl = new FormControl('', []);

  @Input() maxFiles: number = 5
  @Input() maxSize: number = 20

  @Input() readOnly: boolean = false

  @Input() acceptedFiles: string = '.pdf, .jpg, .png, .xlsx, .csv, .excel, .jpeg, .doc, .docx'

  @Input() resetImages: Subject<void> = new Subject<void>()

  @Output() onFileChange: EventEmitter<FileUploaderImages[]> = new EventEmitter<FileUploaderImages[]>();
  @Output() onMaxFiles: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() onMaxSize: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  images: FileUploaderImages[] = []

  subscription: Subscription

  isLoadingFile: boolean = false

  constructor(
    private alertService: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.subscription = this.resetImages.subscribe({
      next: () => {
        this.images = []
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  onChange(event: Event): void {
    if (this.images.length > this.maxFiles) {
      this.onMaxFiles.emit(true)
      return
    } else {
      this.onMaxFiles.emit(false)
    }

    if (
      (event.target as HTMLInputElement)?.files &&
      (event.target as HTMLInputElement)?.files?.length
    ) {
      // @ts-ignore
      const [file]: FileList = event?.target?.files;

      if (!this.validateAllowedExtension(file)) {
        return
      }

      if (file) {
        this.onChangeImage(file);
      }
    }
  }

  showImagesTitle(images: number): string {
    return images > 1 ? `${images} ATTACHED FILES` : `${images} ATTACHED FILE`;
  }

  onChangeImage(image: File): void {
    const reader = new FileReader();

    if (this.calculateFileSize(image.size) > this.maxSize) {
      this.alertService.open('The selected file exceeds the maximum size allowed.', 'OK', {
        duration: 5000,
      })
      return
    }

    this.isLoadingFile = true

    reader.onload = (e: ProgressEvent<FileReader>) => {
      this.isLoadingFile = false

      this.images.push({
        name: image.name,
        target: e.target?.result as string,
        size: image.size,
        file: image,
      })

      if (this.images.length > this.maxFiles) {
        this.onMaxFiles.emit(true)
        return
      } else {
        this.onMaxFiles.emit(false)
      }

      this.onFileChange.emit(this.images);

      this.validateImageSize()

      this.fileInput.nativeElement.value = '';
    };

    reader.readAsDataURL(image);
  }


  countSize(): number {
    return this.images.reduce((acc, image) => acc + image.size, 0);
  }

  sizeInMb(size: number): string {
    return `${(size / 1024 / 1024).toFixed(2)} MB`;
  }

  calculateFileSize(size: number): number {
    return +this.sizeInMb(size).split("MB")[0];
  }

  sizeInMbTotal(): number {
    return Number((this.countSize() / 1024 / 1024).toFixed(2));
  }

  removeImage(index: number): void {
    this.images.splice(index, 1);

    if (this.images.length <= this.maxFiles) {
      this.onMaxFiles.emit(false)
    }

    this.validateImageSize()

    this.onFileChange.emit(this.images);
  }

  deleteImages(): void {
    this.images = [];
    this.onFileChange.emit(this.images);
    this.validateImageSize()
  }

  validateImageSize(): void {
    const actualSize = Number(this.sizeInMb(Number(this.countSize())).split("MB")[0])

    if (actualSize > this.maxSize) {
      this.onMaxSize.emit(true)
      return
    } else {
      this.onMaxSize.emit(false)
    }
  }

  validateAllowedExtension(file: File): boolean {
    const acceptedFiles = this.acceptedFiles;
    const formatedText = acceptedFiles.trim().replace(/\./g, '');
    const extensionArray = formatedText.split(',');

    const allowedExtensions = extensionArray
    const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();

    if (!allowedExtensions.includes(fileExtension as string)) {
      this.alertService.open('File not allowed. Please select a file type: ' + allowedExtensions.join(', '), 'OK', {
        duration: 5000,
      })
      return false
    }

    return true
  }
}
