import { Injectable, InjectionToken } from '@angular/core';
import { ConfiguracionUrl } from '../utils/configuracionUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserLoginService } from '../main/pages/services/login-user.services';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  baseUrl: ConfiguracionUrl;
  urlApi: string;
  tipoUsuario: string;
  headers: any;
  option: any;
  perfil: any;
  datagen: any;
  idtoken: string;
  idDevice: string;
  tokenAcess: '';

  //idtoken = localStorage.getItem(.indexOf("IdToken"));

  constructor(private httpClient: HttpClient, getParametroCognito: UserLoginService) {
    this.baseUrl = new ConfiguracionUrl();
    this.headers = new HttpHeaders({
      'Content-type': 'application/json;charset=utf8',
      'Authorization': this.idtoken
      // 'DeviceKey' : this.idDevice,
      // 'AccessToken' : this.tokenAcess
    });
  }

}
