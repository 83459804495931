<ui-input-select
  label="Seleccionar procesadora"
  [labelColor]="labelColor"
  [valueOptions]="defaultValues"
  (onChangeEvent)="onChange($event)"
  [value]="value"
  [max_width]="max_width"
  [required]="required"
  [multiple]="multiple"
  [disabled]="disabled"
></ui-input-select>
