import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputFieldSuffixActions } from 'app/main/interfaces/input-field-suffix-actions.interface';

export interface CurrencyMaskConfig {
    align: string;
    allowNegative: boolean;
    allowZero: boolean;
    decimal: string;
    precision: number;
    prefix: string;
    suffix: string;
    thousands: string;
    nullable: boolean;
    min?: number;
    max?: number;
    inputMode?: CurrencyMaskInputMode;
}
export declare enum CurrencyMaskInputMode {
    FINANCIAL = 0,
    NATURAL = 1
}

@Component({
    selector: 'ui-currency-field',
    templateUrl: './currency-field.component.html',
    styleUrls: ['./currency-field.component.scss']
})
export class CurrencyFieldComponent implements OnInit {
    @Input() value: FormControl = new FormControl(0)

    @Input() label: string = ''
    @Input() type: string = ''
    @Input() labelColor: string = 'white'
    @Input() currency: string = ''
    @Input() invalidHint: string = 'Completar los requerimientos del campo'

    @Input() required: boolean = false
    @Input() max_width: boolean = false

    @Input() precision: number = 0
    @Input() maxLength: number = 13

    @Input() suffixActions: InputFieldSuffixActions[] = []

    @Output() onChangeEvent: EventEmitter<string> = new EventEmitter<string>()
    @Output() onInputChangeEvent: EventEmitter<string> = new EventEmitter<string>()
    options: CurrencyMaskConfig = {
        prefix: `${this.currency || ''} `,
        thousands: '.', 
        decimal: ',', 
        align: 'left',
        allowNegative: false,
        allowZero: false,
        precision: this.precision || 0,
        suffix: '',
        nullable: false,
    }

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['currency']) {
            this.options = {
                ...this.options,
                prefix: `${this.currency} `,
            }
        }

        if (changes['precision']) {
            this.options = {
                ...this.options,
                precision: this.precision,
            }
        }
    }

    onChange($event: any): void {
        this.onChangeEvent.emit($event)
    }

    onInputChange ($event: any): void {
        this.onInputChangeEvent.emit($event)
    }

    formatAmount(event){
        const value = event.target.value;
        if(value.includes('%') || value.includes('\'')){
           event.target.value = value.replace('%', '').replace('\'', '');
        }
    }

}
