import { Injectable } from '@angular/core';
import { AdminService } from '../services/admin.service';


@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private _adminService: AdminService) { }

  obtenerUsuarios() {
    return this._adminService.usuarios_listar();
  }

}
