<form [formGroup]="form">
    <mat-form-field appearance="outline">
        <mat-label>{{ 'WIDGET_SUPPORT.NAME' | translate }}</mat-label>
        <input matInput required [formControl]="requester_name" [maxlength]="50" type="text">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>{{ 'WIDGET_SUPPORT.EMAIL' | translate }}</mat-label>
        <input matInput required [formControl]="email" [maxlength]="50" type="email">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>{{ 'WIDGET_SUPPORT.SUBJECT' | translate }}</mat-label>
        <input matInput required [formControl]="subject" [maxlength]="50" type="email">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>{{ 'WIDGET_SUPPORT.DESCRIPTION' | translate }}</mat-label>
        <textarea matInput required [formControl]="description_html" [maxlength]="1000" type="text"></textarea>
    </mat-form-field>
    <div class="file-uploader">
        <app-file-uploader #fileUploaderComponent (onMaxFiles)="onMaxFileValidation($event)"
            (onFileChange)="onImagesChange($event)" (onMaxSize)="onMaxSizeValidation($event)"
            [control]="atachments"></app-file-uploader>
        <div class="error-message" *ngIf="maxFilesError">
            {{ 'WIDGET_SUPPORT.MAX_FILE_QUANTITY_MESSAGE' | translate }}
        </div>
        <div class="error-message" *ngIf="maxSizeError">
            {{ 'WIDGET_SUPPORT.MAX_FILE_SIZE_MESSAGE' | translate }}
        </div>
    </div>
</form>