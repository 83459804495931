import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfiguracionUrl } from '../../../../utils/configuracionUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserLoginService } from '../../../pages/services/login-user.services';
import { KeycloakService } from 'keycloak-angular';

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;
  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;
  CODE_DELIVERY_DESTINATION: string;
}

@Injectable({
  providedIn: 'root'
})

export class AdminService {
  baseUrl: ConfiguracionUrl;
  urlApi: string;
  tipoUsuario: string;
  headers: any;
  option: any;
  perfil: any;
  datagen: any;
  idtoken: string;
  idDevice: string;
  tokenAcess: string;

  constructor(private httpClient: HttpClient,private keycloackService: KeycloakService) {
    this.baseUrl = new ConfiguracionUrl();
    this.headers = new HttpHeaders({
      'Content-type': 'application/json;charset=utf8',
      'Authorization': this.getAutorizationHeader()
    });
    //   this.option = new RequestOptions({headers:this.headers});
  }

  getAutorizationHeader(): string {
    return this.keycloackService.getKeycloakInstance().token;
  }

  usuarios_listar(): Observable<any> {

    return this.httpClient.get(this.baseUrl.getUrlMSUsuarios(), { responseType: 'text', headers: this.headers });
  }


}
