import { Injectable, EventEmitter } from '@angular/core';
import { sessionConfiguration } from '../config/config';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private inactivityTimeout: any;
  public inactivityDetected: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    this.setupInactivityDetection();
  }

  private setupInactivityDetection(): void {
    window.addEventListener('mousemove', this.resetInactivityTimer.bind(this));
    window.addEventListener('keypress', this.resetInactivityTimer.bind(this));

    this.startInactivityTimer();
  }

  private startInactivityTimer(): void {
    this.inactivityTimeout = setTimeout(() => {
      this.inactivityDetected.emit();
    }, sessionConfiguration.sessionExpired); 
  }

  private resetInactivityTimer(): void {
    clearTimeout(this.inactivityTimeout);
    this.startInactivityTimer();
  }
}
