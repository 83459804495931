<!-- <ui-input-label [required]="required" [label]="label" [color]="labelColor"></ui-input-label> -->
<mat-form-field
    class="select"
    [ngClass]="max_width ? 'custom-width select' : 'full-width'"
>
    <mat-label>{{ label }}</mat-label>
    <mat-select
        [placeholder]="'Choose an option'"
        class="custom-input-heigth"
        [ngClass]="getClass()"
        [formControl]="value"
        (selectionChange)="onChange($event)"
        [required]="required"
        [disabled]="disabled"
        [multiple]="multiple"
    >
        <span *ngFor="let options of valueOptions">
            <mat-option [value]="options.value">{{ options.label }}</mat-option>
        </span>
    </mat-select>
    <mat-hint *ngIf="value.invalid && value.touched"><span>{{ invalidHint }}</span></mat-hint>
</mat-form-field>
