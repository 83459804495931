import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileUploaderImages } from '../../interfaces/file-uploader.interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
    @Input() control: FormControl = new FormControl('', []);
    @Input() maxFiles: number = 5
    @Input() maxSize: number = 20

    @Input() acceptedFiles: string = '.pdf, .jpg, .png, .xlsx, .csv, .excel, .jpeg, .doc, .docx'

    @Output() onFileChange: EventEmitter<FileUploaderImages[]> = new EventEmitter<FileUploaderImages[]>();
    @Output() onMaxFiles: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    @Output() onMaxSize: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    images: FileUploaderImages[] = []

    constructor(
        private alertService: MatSnackBar,
        private translateService: TranslateService,
    ) { }

    onChange(event: Event): void {
        if (
            (event.target as HTMLInputElement)?.files &&
            (event.target as HTMLInputElement)?.files?.length
        ) {
            // @ts-ignore
            const [file]: FileList = event?.target?.files;

            if (!this.validateAllowedExtension(file)) {
                return
            }

            if (file) {
                this.onChangeImage(file);
            }
        }
    }

    showImagesTitle(images: number): string {
        return images > 1 ? `${images} ${this.translateService.instant('WIDGET_SUPPORT.ATACHED_FILES')}` : `${images} ${this.translateService.instant('WIDGET_SUPPORT.ATACHED_FILES')}`;
    }

    onChangeImage(image: File): void {
        const reader = new FileReader();

        if (this.calculateFileSize(image.size) > this.maxSize) {
            this.alertService.open('El archivo seleccionado excede el tamaño máximo permitido.', 'OK', {
                duration: 5000,
            })
            return
        }

        reader.onload = (e: ProgressEvent<FileReader>) => {
            this.images.push({
                name: image.name,
                target: e.target.result as string,
                size: image.size,
                file: image,
            })

            if (this.images.length > this.maxFiles) {
                this.onMaxFiles.emit(true)
                return
            } else {
                this.onMaxFiles.emit(false)
            }

            this.onFileChange.emit(this.images);

            this.validateImageSize()
        };

        reader.readAsDataURL(image);
    }

    countSize(): number {
        return this.images.reduce((acc, image) => acc + image.size, 0);
    }

    sizeInMb(size: number): string {
        return `${(size / 1024 / 1024).toFixed(2)} MB`;
    }

    calculateFileSize(size: number): number {
        return +this.sizeInMb(size).split("MB")[0];
    }

    sizeInMbTotal(): number {
        return Number((this.countSize() / 1024 / 1024).toFixed(2));
    }

    removeImage(index: number): void {
        this.images.splice(index, 1);

        if (this.images.length <= this.maxFiles) {
            this.onMaxFiles.emit(false)
        }

        this.validateImageSize()

        this.onFileChange.emit(this.images);
    }

    deleteImages(): void {
        this.images = [];
        this.onFileChange.emit(this.images);
        this.validateImageSize()
    }

    validateImageSize(): void {
        const actualSize = Number(this.sizeInMb(Number(this.countSize())).split("MB")[0])

        if (actualSize > this.maxSize) {
            this.onMaxSize.emit(true)
            return
        } else {
            this.onMaxSize.emit(false)
        }
    }

    validateAllowedExtension(file: File): boolean {
        const allowedExtensions = ['pdf', 'jpg', 'png', 'xlsx', 'csv', 'excel', 'jpeg', 'doc', 'docx'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            this.alertService.open('Archivo no permitido. Por favor, selecciona un archivo de tipo: ' + allowedExtensions.join(', '), 'OK', {
                duration: 5000,
            })
            return false
        }

        return true
    }
}
