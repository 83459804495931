import { Component, Input } from '@angular/core';
import { ITableActions } from 'app/main/interfaces';

@Component({
  selector: 'ui-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent {
  @Input() icon: string = 'more_horiz'

  @Input() actions: ITableActions<any>[] = []
}
