import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-support-widget',
    templateUrl: './support-widget.component.html',
    styleUrls: ['./support-widget.component.scss']
})
export class SupportWidgetComponent {
    @Input() metadata: Object = {};
    @Input() sidebarStyle: boolean = false

    isOpen: boolean = false;

    constructor() { }
}
