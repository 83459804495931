import { Component } from '@angular/core';

@Component({
  selector: 'app-support-layout',
  templateUrl: './support-layout.component.html',
  styleUrls: ['./support-layout.component.scss']
})
export class SupportLayoutComponent {

}
