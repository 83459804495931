<div class="base-table">
    <mat-card [ngClass]="matCard ? '' : 'boxShadowNone'">
        <mat-card-title>
            <div class="actions">
                <ng-content #actions></ng-content>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div>
                <table mat-table [dataSource]="baseTable.dataSource" matSort (matSortChange)="sortData($event)">
                    <ng-container *ngFor="let c of baseTable.columns" [matColumnDef]="c.caption">
                        <ng-container *ngIf="c.caption === 'select'">
                            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="c.field.key">
                                <span class="checkbox">
                                    <mat-checkbox (change)="
                                            $event ? toggleAllRows() : null
                                        " [checked]="
                                            selection.hasValue() &&
                                            isAllSelected()
                                        " [indeterminate]="
                                            selection.hasValue() &&
                                            !isAllSelected()
                                        " [aria-label]="checkboxLabel()">
                                    </mat-checkbox>
                                </span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="checkbox">
                                    <mat-checkbox (change)="
                                            $event
                                                ? selection.toggle(element)
                                                : null
                                        " [checked]="
                                            selection.isSelected(element)
                                        " [aria-label]="checkboxLabel(element)">
                                    </mat-checkbox>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container *ngIf="c.caption != 'select'">
                            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="c.field.key">
                                <!-- {{ c.caption | translate }} -->
                                {{ c.caption }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <!-- {{ element[c.field] | translate }} -->
                                {{
                                c.field.parseElement
                                ? c.field.parseElement(element)
                                : element[c.field.key]
                                }}
                            </td>
                        </ng-container>
                    </ng-container>

                    <ng-container matColumnDef="actions-menu" *ngIf="showActions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let elemento">
                            <ui-icon-button [menu_style]="true" [icon]="'more_horiz'"
                                [matMenuTriggerFor]="menu"></ui-icon-button>
                            <mat-menu #menu="matMenu">
                                <ng-container *ngFor="let action of baseTable.actions">
                                    <ui-button [mat_menu]="true" [icon]="action.icon"
                                        (onClick)="action.action(elemento)">{{ action.name
                                        }}</ui-button>
                                </ng-container>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions" *ngIf="showActions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let elemento">
                            <div class="actions">
                                <button mat-icon-button *ngFor="let action of baseTable.actions"
                                    (click)="action.action(elemento)">
                                    <mat-icon>
                                        {{ action.icon }}
                                    </mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </mat-card-content>
        <mat-paginator #paginator 
        [ngClass]="showPaginator ? '' : 'hidePaginator'"
          *ngIf="baseTable.paginateOptions" [length]="baseTable.paginateOptions.totalCount"
            [pageSize]="baseTable.paginateOptions.pageSize" [pageSizeOptions]="baseTable.paginateOptions.pageSizeOptions || [10]" 
            (page)="onPageChangeEvent($event)" showFirstLastButtons>
        </mat-paginator>
    </mat-card>
</div>