import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputFieldSuffixActions } from 'app/main/interfaces/input-field-suffix-actions.interface';

@Component({
  selector: 'ui-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})

export class InputFieldComponent {
  @Input() value: FormControl = new FormControl()

  @Input() label: string = ''
  @Input() default_value: string
  @Input() type: string = ''
  @Input() labelColor: string = 'white'
  @Input() invalidHint: string = 'Completar los requerimientos del campo'
  
  @Input() required: boolean = false
  @Input() textArea: boolean = false
  @Input() max_width: boolean = false
  @Input() disabled: boolean = false
  @Input() maxlength:number;
  @Output() onChangeEvent: EventEmitter<string> = new EventEmitter<string>()

  @Input() suffixActions: InputFieldSuffixActions[] = []

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  onChange($event: any): void {
    this.onChangeEvent.emit($event.target.value)
    this.cd.detectChanges();
  }
}
