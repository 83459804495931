import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/utils/keycloack/services/authentication.service';

@Component({
  selector: 'app-inactivity-modal',
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.scss']
})
export class InactivityModalComponent implements OnInit {
  timer: number = 4;
  confirmedSession: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<InactivityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {

  }
  ngOnInit(): void {
      this.timerInit();
  }

  timerInit(): void {
    setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        if(!this.confirmedSession){
          this.logout();
        }
      }
    }, 1000); // Actualiza el contador cada segundo
  }


  logout(): void {
    this.authenticationService.logoutKeycloak().subscribe(response => {
      this.router.navigateByUrl('').then(() => {
          window.location.reload();
      }
      );
  });
  }

  ngOnDestroy(): void {
    this.timer = 5;
  }

  confirmSession(): void {
    this.confirmedSession = true;
    this.dialogRef.close();

  }
}
