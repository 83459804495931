import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import Swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error && error.error.systemCode === 'ms-experience-front') {
          const errorCode = error.error.errorCode;
          
          if (errorCode === 'B402') {
            
            Swal.fire(
                'Error de autenticancion!',
                'Necesita configurar MFA',
                'warning'
              )
              return throwError(error);
          } else if (errorCode === 'B401') {
            this.authenticationService.logoutKeycloak().subscribe(response => {
                this.router.navigateByUrl('').then(() => {
                    window.location.reload();
                }
                );
            });
            return throwError(error);
          }
        }

        return throwError(error);
      })
    );
  }
}
