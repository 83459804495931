<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg">
                </div>
            </div>

            <div class="px-8 px-md-16">
                <h5>{{idleState}}</h5>
                <!-- <p *ngIf="lastPing"><small>Last keepalive ping <strong>{{lastPing | amTimeAgo}}</strong></small></p> -->
            </div>
            <!-- <div class="px-8 px-md-16"> 
                <button (click)="reset()" *ngIf="timedOut">Restart</button>
            </div> -->
        </div>



        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

           
            &nbsp;&nbsp;
            <div class="toolbar-separator"></div>

            <form [formGroup]="busquedaForm">
                <button mat-button class="user-button" [matMenuTriggerFor]="userMenu3" >
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <!-- <img class="comercioLogo mr-0 mr-sm-16" src="{{imgLogo}}"> -->
                        <span class="username mr-12" fxHide fxShow.gt-sm> {{nameGroup}} </span>
                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>
                <mat-menu #userMenu3="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item *ngFor="let cboGroup of cboGroups" [value]="cboGroup.nameGroup"
                        (click)="changeGroup(cboGroup.merchants,cboGroup.nameGroup)">
                        <span> {{ cboGroup.nameGroup }}</span>
                    </button>
                </mat-menu>
            </form>


            <div class="toolbar-separator"></div>

            <form [formGroup]="busquedaForm">
                <button mat-button [matMenuTriggerFor]="userMenu2" class="user-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="mr-0 mr-sm-16" src="assets/icons/flags/{{imgFlag}}.png" style="width: 18px;">
                        <span class="username mr-12" fxHide fxShow.gt-sm> {{countryCode}} </span>
                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>
                <mat-menu #userMenu2="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item *ngFor="let cboCom of cboMerchant" [value]="cboCom.id"
                        (click)="changeMerchant(cboCom.name,cboCom.id,cboCom.countryCode,cboCom.currencies)">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <img class="mr-0 mr-sm-16" src="assets/icons/flags/{{cboCom.countryCode}}.png" style="width: 18px;">
                            <span> {{ cboCom.countryCode }}</span>
                        </div>

                    </button>
                </mat-menu>
            </form>

            <div class="toolbar-separator"></div>

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/user.png">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{nombreUsuario}} ({{rolUsuario}})</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false" style="overflow: hidden;">
                <div style="min-width: 300px !important;overflow: hidden;">
                    <button mat-menu-item (click)="redirectMFA()" >
                        <mat-icon>settings_cell</mat-icon>
                        <span> {{'MFA.TITLE' | translate}}</span>
                    </button>
                    <button mat-menu-item class="" (click)="logoutUser()"  >
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Logout</span>
                    </button>
                </div>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>