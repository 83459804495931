<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row>
        <div class="title">
            <span class="dialog-title">
                {{title}}
            </span>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content>
    <div class="content">
        <div class="content-image">
            <img src="../../../../../../assets/svg/warning-circle-svgrepo-com.svg" alt="">
        </div>
        <span class="message">{{ message }}</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
    <div>
        <ui-button [full_width]="true" icon="cancel" [bg_white]="true"
            (click)="dialogRef.close(false)">{{ 'ELEM_GENERIC.CANCELAR' | translate }}</ui-button>
    </div>
    <div>
        <ui-button [full_width]="true" icon="check" [bg_white]="true"
            (click)="dialogRef.close(true)">{{ 'ELEM_GENERIC.CONFIRMAR' | translate }}</ui-button>
    </div>
</mat-dialog-actions>