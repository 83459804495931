import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss']
})
export class PageTemplateComponent {
  @Input() title: string = ''
  @Input() translateTitle: string = ''
  @Input() icon: string = 'dashboard'

  @Input() filters: boolean = false
  @Input() actions: boolean = true

  @Input() showBackButton: boolean = false;

  @Output() back = new EventEmitter<void>();
  onBack() {
    this.back.emit();
  }
}
