import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICustomSelectModel } from 'app/main/interfaces';
import { MerchantService } from 'app/services/pay-in/merchant.service';
import { Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'ui-processor-cash-select',
  templateUrl: './processor-cash-select.component.html',
  styleUrls: ['./processor-cash-select.component.scss']
})
export class ProcessorCashSelectComponent implements OnInit {
    @Input() labelColor: string = 'white'

    @Input() value: FormControl = new FormControl('ALL')
  
    @Input() required: boolean = false
    @Input() max_width: boolean = false
    @Input() multiple: boolean = false
    @Input() disabled: boolean = false
    @Input() allOption: boolean = false
  
    @Output() onChangeEvent: EventEmitter<ICustomSelectModel> = new EventEmitter<ICustomSelectModel>()
  
    processorSubscription: Subscription
  
    constructor(private merchantService: MerchantService,private cd: ChangeDetectorRef ) { }
  
    defaultValues: ICustomSelectModel[] = [
      { label: 'Cobro digital', value: 'ALL' },
    ]
  
    ngOnInit(): void {
     this.processorSubscription =  this.merchantService.getProcessorsByCountryAndMethod('ARG', 'Cash').subscribe((result) => {
        this.defaultValues = result.map((result) => {
          return {
            label: result.name,
            value: result.id,
          }
        })
        this.cd.detectChanges()
      }, (err) => {
        return throwError(() => err)
      })
    }
  
    ngAfterViewInit(): void {
      this.cd.detectChanges()
    }
    
    ngOnDestroy(): void {
      this.processorSubscription.unsubscribe()
    }
  
    onChange($event: ICustomSelectModel) {
      this.onChangeEvent.emit($event)
    }
}
