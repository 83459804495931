<button mat-flat-button [ngClass]="sidebarStyle ? 'sidebar-style' : ''" (click)="isOpen = !isOpen" type="button"
    cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <div>
        <img src="../../../../../../../assets/svg/support.svg" alt="">
        <span>{{ 'WIDGET_SUPPORT.SUPPORT' | translate }}</span>
    </div>
</button>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
    <div class="overlay-container">
        <app-support-container [metadata]="metadata" (onClosePopup)="isOpen = !isOpen"></app-support-container>
    </div>
</ng-template>