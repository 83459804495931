<mat-form-field [ngClass]="max_width ? 'custom-width select' : 'full-width'">
    <mat-label>{{ label }}</mat-label>
    <input [readonly]="disabled" placeholder="{{'INPUT.PLACEHOLDER' | translate}}" matInput *ngIf="!textArea"
        [defaultValue]="default_value" [formControl]="value" [type]="type" (input)="onChange($event)"
        [required]="required"   [maxlength]="maxlength" class="arrow-number" />
    <span class="text-area">
        <textarea [readonly]="disabled" *ngIf="textArea" [placeholder]="'Write your comments here...'" matInput
            [defaultValue]="default_value" [formControl]="value" [type]="type" (input)="onChange($event)"
            [required]="required"></textarea>
    </span>
    <button *ngFor="let suffixAction of suffixActions" [matTooltip]="suffixAction.tooltip"
        (click)="suffixAction.action()" matSuffix mat-icon-button>
        <mat-icon>{{suffixAction.icon}}</mat-icon>
    </button>
    <mat-hint *ngIf="value.invalid && value.touched"><span>{{ invalidHint }}</span></mat-hint>
</mat-form-field>