import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'ui-slide-toggle',
    templateUrl: './slide-toggle.component.html',
    styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit {
    @Input() color: ThemePalette = 'primary';

    @Input() label: string = ''

    @Input() checked: boolean = false;
    @Input() disabled: boolean = false;

    @Output() onToggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit(): void {
    }

    onChange(): void {
        this.onToggleChange.emit(!this.checked)
    }
}
