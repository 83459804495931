<input type="file" [max]="maxFiles" hidden [accept]="acceptedFiles" (change)="onChange($event)" #fileInput />

<div class="content">
    <button type="button" class="button-uploader" matTooltip="Editar imagen" (click)="fileInput.click()" mat-button>
        <img src="../../../../../../../assets/svg/cloud-upload.svg" alt="">
        <p>{{ 'WIDGET_SUPPORT.MAX_FILE_INFO' | translate }}</p>
        <p>{{ 'WIDGET_SUPPORT.FILE_CLICK' | translate }}</p>
    </button>

    <div class="img" *ngIf="images.length > 0">
        <p class="img-title">{{ showImagesTitle(images.length) }} </p>
        <div class="loop-container">
            <div *ngFor="let image of images; let i = index" class="images-container">
                <div class="item">
                    <div class="item-title">
                        <p>{{
                            image.name
                            ? (image.name.substring(0, 22) + (image.name.length > 22 ? '...' : '')) : 'No tiene' }}</p>
                        <p class="size">
                            {{ sizeInMb(image.size) }}
                        </p>
                    </div>
                    <button mat-icon-button color="primary" (click)="removeImage(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>