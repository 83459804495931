import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfiguracionUrl } from 'app/utils/configuracionUrl';
import { Navigation } from 'app/main/models/navigation.model';
import { Observable } from 'rxjs/internal/Observable';
import { CreateUserRequest } from './models/user/create-user-request';
import { CreateUserResponse } from './models/user/create-user-response';
import { ListRolesResponse } from './models/user/list-roles-response';
import { ListUsersResponse } from './models/user/list-users-response';
import { UpdateUserRequest } from './models/user/update-user-request';
import { UpdateUserResponse } from './models/user/update-user-response';
import { BehaviorSubject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    merchantSelectedName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    baseUrl: ConfiguracionUrl;

    constructor(private httpClient: HttpClient, private keycloackService: KeycloakService) {
        this.baseUrl = new ConfiguracionUrl();
    }

  

    obtenerUsuario(id: number): Observable<ListUsersResponse> {
        return this.httpClient.get<ListUsersResponse>(this.baseUrl.getUrlMSUsuarios() + id, );
    }

    obtenerUsuarioPorUsernameYMerchantId(username?: string, merchantId?: string): Observable<ListUsersResponse> {
        let queryParams = new HttpParams()
        if (merchantId != null) {
            queryParams = queryParams.append('merchantId', merchantId)
        }

        if(username){
            queryParams = queryParams.append('name', username)
            return this.httpClient.get<ListUsersResponse>(this.baseUrl.getUrlMSUsuarios() + `/search-user/${merchantId}/${username}`);
        }else{

            return this.httpClient.get<ListUsersResponse>(this.baseUrl.getUrlMSUsuarios() + `/search/${merchantId}`);
        }
    }

    listarUsuarios(): Observable<ListUsersResponse> {
        return this.httpClient.get<ListUsersResponse>(this.baseUrl.getUrlMSUsuarios());
    }

    editarUsuario(httpBody: UpdateUserRequest): Observable<UpdateUserResponse> {

        return this.httpClient.put<UpdateUserResponse>(this.baseUrl.getUrlMSActualizarUsuario(), httpBody);
    }

    crearUsuario(httpBody: CreateUserRequest): Observable<CreateUserResponse> {

        return this.httpClient.post<CreateUserResponse>(this.baseUrl.getUrlMSCrearUsuario(), httpBody);
    }

    obtenerRoles(type: string): Observable<ListRolesResponse> {

        let queryParams = new HttpParams()
        if (type != null) {
            queryParams = queryParams.append('type', type)
        }
        return this.httpClient.get<ListRolesResponse>(this.baseUrl.getUrlMSObtenerRoles(), {  params: queryParams  });
    }


}
