import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { JwtHelperService } from '@auth0/angular-jwt';



import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { GetParametrosCognito } from 'app/services/getParametrosCognito.service';
import { UsuariosService } from 'app/main/apps/admin/usuarios/usuarios.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AdminService } from 'app/main/apps/admin/services/admin.service';
import { ConfiguracionUrl } from '../../../utils/configuracionUrl';

//timer
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UserService } from 'app/services/pay-in/user.service';
import { MerchantService } from '../../../services/pay-in/merchant.service';
import { SharedService } from '../../../main/pages/services/shared-service.service';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarService } from './services/toolbar.service';
import { KeycloakService } from "keycloak-angular";
import { AuthenticationService } from 'app/utils/keycloack/services/authentication.service';
import { environment } from 'environments/environment';

const RolesEnum = {
    "1": "Admin Merchant",
    "2": "Visor Merchant",
    "3": "Administrador Monnet",
    "4": "Visor Monnet",
    "5": "Integrador Merchant",
    "6": "Administrador Gral Monnet",
    "7": "Link de Pagos",
}
@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    //timer
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;


    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    helper: JwtHelperService;
    username: string = "";
    userCom: any = '';
    cboMerchantId = 0;

    //barra comercio
    nameMerchant: any;
    busquedaForm: FormGroup;
    nombreUsuario: any;
    rolUsuario: any;
    baseUrl: any;
    imgLogo: any;

    cmbCurrency: any[];
    countryCode: any;
    imgFlag: string = 'default';

    cboMerchant: any[] = [];
    cboGroups: any[];
    nameGroup: any;
    // Private
    private _unsubscribeAll: Subject<any>;
    //comercio_byuser/username
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public router: Router,
        public authService: GetParametrosCognito,
        public _usuarioService: UsuariosService,
        private formBuilder: FormBuilder,
        public _adminService: AdminService,
        public _userService: UserService,
        public _sharedService: SharedService,
        //timer
        private idle: Idle,
        private keepalive: Keepalive,
        private _matDialog: MatDialog,
        private _merchantSetvice: MerchantService,
        private toolbarService: ToolbarService,
        private keycloakService: KeycloakService,
        private authenticationService: AuthenticationService
    ) {
        this.initBuscador();
        let idMerchant = this.keycloakService.getKeycloakInstance().tokenParsed['merchantId'][0];
        this.getMerchants(idMerchant);
        this.obtenerPerfilUsuario();
        this.reset();

        this.baseUrl = new ConfiguracionUrl();
        this.helper = new JwtHelperService();
        let token = this.authService.getIdToken();
        const decodedToken = this.helper.decodeToken(token);
   

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'es',
                title: 'Espanol',
                flag: 'es'
            }
            ,
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            },
            {
                id: 'pe',
                title: 'Espanol',
                flag: 'pe'
            }

        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
    }

    //timer
    reset() {
        this.idle.watch();
        this.idleState = '';
        this.timedOut = false;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        //console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logoutUser() {
        this.authenticationService.logoutKeycloak().subscribe(response => {
            this.router.navigateByUrl('').then(() => {
                window.location.reload();
            }
            );
        });
    }
    btnProfile() {

        this.router.navigate(["apps/admin/profile"])
    }
    initBuscador() {
        this.busquedaForm = this.formBuilder.group({
            comercio: new FormControl('', null)
        });
    }

    obtenerPerfilUsuario() {
        this.nombreUsuario = this.keycloakService.getKeycloakInstance().tokenParsed['name'];
        this.keycloakService.getKeycloakInstance().realmAccess['roles'].forEach(rol => {
                if (RolesEnum[rol]) {
                    this.rolUsuario = RolesEnum[rol];
                    return;
                }
        });
        localStorage.setItem('roleType', String(this.rolUsuario));
        localStorage.setItem('email', String(this.keycloakService.getKeycloakInstance().tokenParsed['email']));
        localStorage.setItem('merchantId', String(this.keycloakService.getKeycloakInstance().tokenParsed['merchantId'][0]))
        sessionStorage.setItem("username", String(this.keycloakService.getKeycloakInstance().tokenParsed['name']))
    }

    changeMerchant(nombre, id, imglogo, cboMonedas) {
        this.nameMerchant = nombre;
        this.cboMerchantId = id;
        this.imgFlag = imglogo;
        this.countryCode = imglogo;

        localStorage.setItem('merchantId', '');

        this._sharedService.changeMessageMoneda(imglogo);
        this._sharedService.changeMessage(cboMonedas);
        this._sharedService.changeMessageMerchantId(this.cboMerchantId);

        localStorage.setItem('merchantId', JSON.stringify(this.cboMerchantId));

        this.toolbarService.selectedMerchant = {
            selectedMerchant: this.toolbarService.selectedMerchant.selectedMerchant,
            selectedCountry: String(this.countryCode),
            selectedMerchantId: String(this.cboMerchantId)
        }
    }

    getMerchants(merchantId) {
        this._merchantSetvice.listarComerciosPorGrupo(merchantId).subscribe(
            (data) => {
                if(data){
                    this.nameGroup = data[0]['nameGroup'];
                    this._userService.merchantSelectedName.next(this.nameGroup);
                   
                    this.cboMerchant = data[0]['merchants'];
                    data.sort((a, b) => {
                        return a.nameGroup.localeCompare(b.nameGroup);
                    }
                    );  
                    this.cboGroups = data;
                    this.changeGroup(this.cboMerchant, this.nameGroup);
                }
            });
    }

    changeGroup(merchantsData?, nombreGrupo?) {
        localStorage.setItem('merchantId', '');

        this.nameGroup = nombreGrupo;
        this._userService.merchantSelectedName.next(this.nameGroup);
        this.cboMerchant = merchantsData;
        this.nameMerchant = this.cboMerchant[0]['name'];
        this.cboMerchantId = this.cboMerchant[0]['id'];
        this.countryCode = merchantsData[0]['countryCode']
        this.cmbCurrency = merchantsData[0]['currencies'];
        this.imgFlag = merchantsData[0]['countryCode'];

        localStorage.setItem('merchantId', JSON.stringify(this.cboMerchantId));

        this._sharedService.changeMessageMoneda(this.countryCode);
        this._sharedService.changeMessage(this.cmbCurrency);
        this._sharedService.changeMessageMerchantId(this.cboMerchantId);

        this.toolbarService.selectedMerchant = {
            selectedMerchant: merchantsData,
            selectedCountry: merchantsData[0]['countryCode'],
            selectedMerchantId: this.cboMerchant[0]['id']
        }
    }

    redirectMFA(){
        window.location.href = environment.keycloak.urlOtp
    }
}
