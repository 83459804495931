import { CreateHelpDeskTicketPayload } from "../interfaces/support.interfaces";

export const initWidget: CreateHelpDeskTicketPayload = {
    helpdesk_ticket: {
        description_html: '',
        subject: '',
        email: '',
        requester_name: '',
        custom_field: {
            cf_fsm_contact_name_1921958: '',
        },
        atachments: []
    }
};