

<ng-container *ngIf="url == '/support'; else fuseTemplate">
    <app-support-page class="support"></app-support-page>
</ng-container>
<ng-template #fuseTemplate>

<fuse-progress-bar></fuse-progress-bar>

<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>

<div class="widget">
    <app-support-widget *ngIf="showWdiget" [metadata]="metadata"></app-support-widget>
</div>

</ng-template>
