import { ISidebarRoutes } from "app/main/interfaces";
import { environment } from "environments/environment";

const roles = environment.roles;

export const SidebarRoutes: ISidebarRoutes[] = [
    {
        children: [
            {
                id: "dash.resumen",
                title: "Resumen",
                translate: "NAV.DASH.RESUMEN",
                type: "item",
                url: "/apps/dashboards/project",
                roles: [roles.AdminMerchant, roles.VisorMerchant,roles.AdministradorMonnet, roles.VisorMonnet,roles.IntegradorMerchant,roles.AdministradorGeneralMonnet],
                showByEnvironment: true,
            }
        ],
        title: "Dashboard",
        translate: "NAV.DASHBOARDS",
        type: "collapsable",
        url: "",
        icon: 'dashboard',
        id: 'dashboard',
    },
    {
        children: [
            {
                id: "transaction.report",
                title: "Reportedetransacciones",
                translate: "NAV.REPORT.TRANSACCION",
                type: "item",
                url: "/apps/reportes/reporte-transaccion",
                roles: [roles.AdminMerchant, roles.VisorMerchant,roles.AdministradorMonnet,roles.VisorMonnet,roles.IntegradorMerchant,roles.AdministradorGeneralMonnet],
                showByEnvironment: true,
            },
            {
                id: "operative.report",
                title: "Reporteoperativo",
                translate: "NAV.REPORT.OPERATIVO",
                type: "item",
                url: "/apps/reportes/reporte-operativo",
                roles: [roles.AdministradorMonnet,roles.IntegradorMerchant,roles.AdministradorGeneralMonnet],
                showByEnvironment: true,
            },
            {
                id: "massive.update.report",
                title: "ActualizaciónMasiva",
                translate: "NAV.REPORT.ACTUALIZACION",
                type: "item",
                url: "/apps/reportes/actualizacion-masiva",
                roles: [roles.AdministradorMonnet, roles.AdministradorGeneralMonnet],
                showByEnvironment: true,
            },
            {
                id: "report.by.files",
                title: "ReporteporArchivos",
                translate: "NAV.REPORT.ARCHIVOS",
                type: "item",
                url: "/apps/reportes/reporte-por-archivos",
                roles: [roles.AdministradorMonnet],
                showByEnvironment: true,
            },
            {
                id: "report.extended",
                title: "ReporteExtendido",
                translate: "NAV.REPORT.EXTENDIDO",
                type: "item",
                url: "/apps/reportes/reporte-extendido",
                roles: [roles.AdminMerchant,roles.VisorMerchant,roles.AdministradorMonnet,roles.VisorMonnet, roles.IntegradorMerchant,roles.AdministradorGeneralMonnet],
                showByEnvironment: true,
            }
        ],
        icon: "list_alt",
        id: "reporte",
        title: "Reporte",
        translate: "NAV.REPORTE",
        type: "collapsable",
        url: "",
    },
    {
        children: [
            {
                id: "balance.consult2",
                title: "BalancedeLiquidaciónPayin",
                translate: "NAV.BALANCEV2.PAYINS",
                type: "item",
                url: "/apps/balance/consulta-balance",
                roles: [roles.AdminMerchant,roles.VisorMerchant, roles.AdministradorMonnet,roles.AdministradorGeneralMonnet],
                showByEnvironment: true,

            },
            {
                id: "balance.extended",
                title: "BalanceExtendido",
                translate: "NAV.BALANCEV2.EXTENDIDO",
                type: "item",
                url: "/apps/balance/balance-extendido",
                roles: [roles.AdminMerchant,roles.VisorMerchant, roles.AdministradorMonnet,roles.AdministradorGeneralMonnet],
                showByEnvironment: true,
            }
        ],
        icon: "account_balance",
        id: "balance2",
        title: "Balance",
        translate: "NAV.BALANCEV2.TITULO",
        type: "collapsable",
        url: "",
    },
    {
        children: [
            {
                id: "adm.usuario",
                title: "ListadeUsuarios",
                translate: "NAV.ADM.USUARIOS",
                type: "item",
                url: "/apps/admin/usuarios",
                roles: [roles.AdminMerchant, roles.AdministradorMonnet,roles.AdministradorGeneralMonnet],
                showByEnvironment: true,
            },
            {
                id: "adm.comercio",
                title: "ListadeComercios",
                translate: "NAV.ADM.COMERCIOS",
                type: "item",
                url: "/apps/admin/comercios",
                roles: [ roles.AdministradorMonnet],
                showByEnvironment: true,

            },{
                id:"adm.usuario",
                title:"Datosdelcomercio",
                translate:"NAV.ADM.MERCHANT",
                type:"item",
                url:"/apps/admin/comercio",
                roles: [ roles.IntegradorMerchant],
                showByEnvironment: true,
            },
            {
                id: "adm.grupo",
                title: "ListadeGrupos",
                translate: "NAV.ADM.GRUPOS",
                type: "item",
                url: "/apps/admin/grupos",
                roles: [roles.AdministradorMonnet],
                showByEnvironment: true,
            },
            {
                id: "adm.pushpays",
                title: "ModuloPushPays",
                translate: "NAV.ADM.PUSHPAYS",
                type: "item",
                url: "/apps/admin/pushpay",
                roles: [roles.AdministradorMonnet,roles.AdministradorGeneralMonnet],
                showByEnvironment: true,
            },
            {
                id: "adm.tarifa",
                title: "ListadeTarifas",
                translate: "NAV.ADM.TARIFAS",
                type: "item",
                url: "/apps/admin/rates",
                roles: [roles.AdministradorMonnet],
                showByEnvironment: false,
            },
            {
                id: "adm.tarifa",
                title: "ListadeTarifas",
                translate: "NAV.ADM.TARIFAS",
                type: "item",
                url: "/apps/admin/rates-detail",
                roles: [roles.AdministradorMonnet],
                showByEnvironment: environment.showByEnvironment,

            },
            {
                id: "adm.ruteo",
                title: "ModuloRuteo",
                translate: "NAV.ADM.RUTEO",
                type: "item",
                url: "/apps/admin/comercio-ruteo",
                roles: [roles.AdministradorMonnet],
                showByEnvironment: true,
            },
            {
                id: "ADM.SUBMENU_CONVERSION",
                title: "SubmenuConversion",
                translate: "NAV.ADM.SUBMENU_CONVERSION",
                type: "item",
                url: "/apps/admin/submenu-conversion",
                roles: [roles.AdministradorMonnet],
                showByEnvironment: true,
            },
            {
                id: "ADM.TRASLADO",
                title: "TrasladoEfectivo",
                translate: "NAV.ADM.TRASLADO",
                type: "item",
                url: "/apps/admin/traslado-efectivo",
                roles: [roles.AdministradorMonnet],
                showByEnvironment: true,
            }
        ],
        icon: "supervisor_account",
        id: "admin",
        title: "Admin",
        translate: "NAV.ADMIN",
        type: "collapsable",
        url: "",
    },
    {
        children: [
            
            {
                id: "linkdepagos.generacionlink",
                title: "GeneraciónLinkdePagos",
                translate: "PAYMENT_LINK.BASE.GENERATION_TITLE",
                type: "item",
                url: "/apps/link-de-pagos/generar-link-de-pago",
                roles: [roles.AdministradorMonnet,roles.VisorMerchant,roles.AdminMerchant,roles.UsuarioLinkDePago],
                showByEnvironment: true,
            },
            {
                id: "linkdepagos.configuracion",
                title: "ConfiguracionLinkdePagos",
                translate: "PAYMENT_LINK.BASE.CONFIGURATION_TITLE",
                type: "item",
                url: "/apps/link-de-pagos/configuracion-link-de-pago",
                roles: [roles.AdministradorMonnet,roles.IntegradorMerchant],
                showByEnvironment: true,
            },
            ,
            {
                id: "linkdepagos.listadoLinksGenerados",
                title: "ListadoLinksGenerados",
                translate: "PAYMENT_LINK.BASE.LIST_TITLE",
                type: "item",
                url: "/apps/link-de-pagos/listado-links-generados",
                roles: [roles.AdministradorMonnet,
                    roles.AdministradorGeneralMonnet, 
                    roles.VisorMonnet,
                    roles.AdminMerchant,
                    roles.IntegradorMerchant,
                    roles.VisorMerchant,
                    roles.UsuarioLinkDePago],
                    showByEnvironment: true,
            },
            {
                id: "linkdepagos.procesamientoMasivo",
                title: "ProcesamientoMasivo",
                translate: "PAYMENT_LINK.BASE.PROCESS_TITLE",
                type: "item",
                url: "/apps/link-de-pagos/procesamiento-masivo",
                roles: [roles.AdministradorMonnet,roles.VisorMerchant,roles.AdminMerchant,roles.UsuarioLinkDePago],
                showByEnvironment: true,
            },
        ],
        icon: "payment",
        id: "linkdepagos",
        title: "Link de Pagos",
        translate: "NAV.PAYMENT_LINK",
        type: "collapsable",
        url: "",
    },
    
]
