<div class="widget-container" [ngClass]="fullScreen ? 'full-screen' : ''">
    <div class="title">
        <h2>{{ 'WIDGET_SUPPORT.WELCOME_MESSAGE' | translate }}</h2>
        <button mat-icon-button (click)="onClosePopup.emit()" *ngIf="!fullScreen">
            <mat-icon>close</mat-icon>
        </button>

        <div class="back-to-login" *ngIf="fullScreen">
            <button   mat-button (click)="backToLogin()">
              <mat-icon class="mr-2">exit_to_app</mat-icon>
              <span>Login</span>
            </button>
          </div>
    </div>
    <div class="form">
        <mat-card>
            <mat-card-title>
                <h3>{{ 'WIDGET_SUPPORT.CONTACT_US' | translate }}</h3>
            </mat-card-title>
            <app-support-form #supportFormComponent [formInput]="initialFormValue"></app-support-form>
            <button class="action" mat-flat-button [color]="'primary'" *ngIf="!isLoading" (click)="createTicket()">
                <div>
                    <span>{{ 'WIDGET_SUPPORT.SEND' | translate }}</span>
                </div>
            </button>
            <p *ngIf="isLoading">{{ 'WIDGET_SUPPORT.SENDING' | translate }}</p>
        </mat-card>
    </div>
</div>