import { Injectable } from '@angular/core';
import { GroupBean } from 'app/services/pay-in/models/merchant/merchants-group-bean';
import { BehaviorSubject } from 'rxjs';

interface ISelectedMerchant {
    selectedMerchant: GroupBean[]
    selectedCountry: string
    selectedMerchantId: string
}

@Injectable({
    providedIn: 'root'
})

export class ToolbarService {
    selectedMerchantSubject: BehaviorSubject<ISelectedMerchant> = new BehaviorSubject<ISelectedMerchant>(null);
    selectedMerchant$ = this.selectedMerchantSubject.asObservable();

    set selectedMerchant(value: ISelectedMerchant) {
        this.selectedMerchantSubject.next(value);
    }

    get selectedMerchant(): ISelectedMerchant {
        return this.selectedMerchantSubject.value;
    }

    constructor() { }
}
