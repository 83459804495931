import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportFormComponent } from './components/support-form/support-form.component';
import { SupportContainerComponent } from './components/support-container/support-container.component';
import { SupportWidgetComponent } from './components/support-widget/support-widget.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { TranslateModule } from '@ngx-translate/core';
import { SupportLayoutComponent } from './components/support-layout/support-layout.component';
import { SupportPageComponent } from './support-page/support-page.component';

@NgModule({
    declarations: [
        SupportFormComponent,
        SupportContainerComponent,
        SupportWidgetComponent,
        FileUploaderComponent,
        SupportLayoutComponent,
        
        SupportPageComponent,
    ],
    imports: [
        CommonModule,
        OverlayModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatCardModule,
        TranslateModule.forChild(),
    ],
    exports: [
        SupportWidgetComponent,
        SupportPageComponent
    ]
})
export class SupportModule { }
