<form [formGroup]="form">
    <div class="datepicker-select">
        <!-- <ui-input-label [color]="labelColor" [label]="startDateLabel" [required]="startDateRequired"></ui-input-label> -->
        <mat-form-field class="datepicker" [ngClass]="max_width ? 'custom-width select' : 'full-width'">
            <mat-label>{{ startDateLabel }}</mat-label>
            <input [disabled]="disabled" matInput class="custom-input-heigth" [readonly]="true" [formControl]="startDate" [required]="startDateRequired"
                [placeholder]="startDayPlaceholder" [matDatepicker]="sdate" (dateInput)="onStartDateChange($event)" />
            <mat-datepicker-toggle matSuffix [for]="sdate">
            </mat-datepicker-toggle>
            <mat-datepicker #sdate></mat-datepicker>
            <mat-hint *ngIf="startDate.invalid"><span>{{ invalidHint }}</span></mat-hint>
        </mat-form-field>
    </div>
    <div class="datepicker-select" *ngIf="isRange">
        <!-- <ui-input-label [color]="labelColor" [label]="endDateLabel" [required]="endDateRequired"></ui-input-label> -->
        <mat-form-field *ngIf="isRange" class="datepicker" [ngClass]="max_width ? 'custom-width select' : 'full-width'">
            <mat-label>{{ endDateLabel }}</mat-label>
            <input matInput [disabled]="disabled" class="custom-input-heigth" [formControl]="endDate" [required]="endDateRequired"
                [placeholder]="endDayPlaceHolder" [matDatepicker]="edate" (dateInput)="onEndDateChange($event)" />
            <mat-datepicker-toggle matSuffix [for]="edate"></mat-datepicker-toggle>
            <mat-datepicker #edate></mat-datepicker>
            <mat-hint *ngIf="startDate.invalid"><span>{{ invalidHint }}</span></mat-hint>
        </mat-form-field>
    </div>
</form>

<!-- placeholder="{{ startDateLabel | translate }}" -->
<!-- placeholder="{{ endDateLabel | translate }}" -->