import { Injectable } from '@angular/core';
import { FreshDeskApiService } from './fresh-desk-api.service';
import { Observable, Subject, Subscription, of, throwError } from 'rxjs';
import { CreateHelpDeskTicketPayload, HelpDeskTicketResponse } from '../interfaces/support.interfaces';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SupportService {
    private ticketSubscription: Subscription;

    private dispatchTicket: Subject<CreateHelpDeskTicketPayload> = new Subject<CreateHelpDeskTicketPayload>();
    private ticketDispatched: Subject<HelpDeskTicketResponse> = new Subject<HelpDeskTicketResponse>();
    private ticketDispatchError: Subject<Error> = new Subject<Error>();

    /*
        This is the request observable that will be used to dispatch the ticket.
    */
    set dispatchTicketRequest(ticketData: CreateHelpDeskTicketPayload) {
        this.dispatchTicket.next(ticketData);
    }

    /*
        This is the response observable that will be used to display the response message.
    */
    get ticketDispatched$(): Observable<HelpDeskTicketResponse> {
        return this.ticketDispatched.asObservable();
    }

    /*
        This is the error observable that will be used to display the error message.
    */
    get ticketDispatchError$(): Observable<Error> {
        return this.ticketDispatchError.asObservable();
    }

    constructor(private freshDeskService: FreshDeskApiService) {
        this.initializeTicketDispatch();
    }

    /*
        Initialize the ticket dispatch subscription.
    */
    private initializeTicketDispatch(): void {
        this.ticketSubscription = this.dispatchTicket.pipe(
            switchMap((ticketData: CreateHelpDeskTicketPayload) => {
                return this.freshDeskService.createTicket(ticketData)
                    .pipe(
                        catchError((error: Error) => {
                            return of(error);
                        })
                    )
            })
        ).subscribe({
            next: (response: HelpDeskTicketResponse | any) => {
                if (!response.error) {
                    this.ticketDispatched.next(response);
                }

                if (response.error) {
                    this.ticketDispatchError.next(response);
                }
            },
            error: (error: Error) => {
                this.ticketDispatchError.next(error);
            }
        });
    }

    public stopTicketDispatch(): void {
        if (this.ticketSubscription) {
            this.ticketSubscription.unsubscribe();
        }
    }
}
