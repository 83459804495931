import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InactivityModalComponent } from './components/inactivity-modal/inactivity-modal.component';
import { UiModule } from '../ui/ui.module';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';




@NgModule({
  declarations: [InactivityModalComponent],
  imports: [
    CommonModule,
    UiModule,
    MatDialogModule,
    MatToolbarModule,
    MatCardModule,
    TranslateModule.forChild(),
  ],
  exports: [
    InactivityModalComponent
  ],
})
export class SessionModule { }
